import {useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {createUser, fetchUser, selectorUserData} from "../../redux/slices/userSlice";
import {useInitData, initMainButton, initUtils, initBackButton} from '@telegram-apps/sdk-react';
import {useTelegram} from "../../hooks/useTelegram";
import React, {useEffect, useState} from "react";
import axios from 'axios';
import Menu from "../../components/Menu";
import Modal from "../../components/modal/Modal";
import useAppContext from '../../state/useAppContext';
import './Friends.css'
import { useTranslation } from 'react-i18next';
const API_URL = process.env.REACT_APP_API_URL;

function Friends() {
    const { t } = useTranslation();
    const { user, actions, isLoading, error } = useAppContext();
    //const dispatch = useDispatch();
    const initData = useInitData();
    const tgUser = initData.user;
    const {tg} = useTelegram();
    const utils = initUtils();
    //const user = useSelector(selectorUserData)
    const shareUrl = `https://t.me/chu_chu_test_bot?start=ref_${tgUser.id}`;
    const userId = initData.user.id;
    const [referrers, setReferrers] = useState([]);
    const [copyAlert, setCopyAlert] = useState(false)
    const [backButton] = initBackButton();
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    backButton.on('click', () => {
        backButton.hide();
        navigate('/');
    });

    const getReferrers = async (userId) => {
        const res = await axios.get(`${API_URL}/users/get-referrers/${userId}`);
        return res.data;
    }

    useEffect(() => {
        backButton.show();
    }, []);

    useEffect(() => {
        const fetchReferrers = async () => {
            try {
                const referrersData = await getReferrers(userId);
                setReferrers(referrersData); // Set the friends data to state
            } catch (error) {
                console.error("Failed to fetch referrers", error);
            }
        };

        if (userId) {
            fetchReferrers(); // Fetch friends when userId is available
        }

    }, [userId]);


    const handleShare = () => {
        utils.shareURL(shareUrl);
        setIsModalOpen(false)
    }

    const handleCopyInviteLink = () => {
        navigator.clipboard.writeText(shareUrl).then(() => {
            setCopyAlert(true);
            setTimeout(() => setCopyAlert(false), 1500)
        });
        //setIsModalOpen(false)
    }

    return (
        <div className="page">
        <div className="friends-page">
            {referrers.length === 0 ? (
                <div className="tasks-page">
                    <h1>{t('No frens yet 😭')}</h1>
                    <p>{t('Hope you have some frens. If they active they will help you earn')}</p>
                </div>
            ) : (
                <div>
                    <h2 style={{color: 'white', textAlign: 'center'}}>{referrers.length === 1 ? '1' : referrers.length} friend{referrers.length > 1 ? 's' : ''}</h2>
                    <p style={{color: 'white'}}>{t('Invite more friends and earn more coins every time they do!')}</p>
                    <br/>
                    <div className="tasks-list">
                        {referrers.map((r) => <p className="task-item">  {r.username} - 🟡{r.score}</p>)}
                    </div>
                </div>
            )}


            {isModalOpen && (
                <Modal
                    isOpen
                    onClose={() => setIsModalOpen(false)} // Close modal
                    title={t('Invite friends')}
                >
                    <hr/>
                    <br/>
                    <button className={`button-red ${copyAlert ? '' : ''}`} onClick={handleCopyInviteLink}>
                         {copyAlert ? t('Copied') : t('Copy invite link')}
                    </button>
                    <br/>
                    <br/>
                    <button className="button-red" onClick={handleShare}>{t('Share invite link')}</button>
                </Modal>
            )}

            <button onClick={() => setIsModalOpen(true)} className="button-red">{t('Invite friends')}</button>
        </div>
        {/* <div className="menu-container">
            <Menu/>
        </div> */}
        </div>
    );
}

export default Friends;

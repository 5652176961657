import React, {useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import { useTonWallet, useTonConnectUI } from '@tonconnect/ui-react';

const Boost = ({icon, title, clickerLevel, isMaxLevel, nextLevelValue, incrementLevel, score}) => {
    const { t } = useTranslation();
    const [isActive, setActive] = useState(false);
    const wallet = useTonWallet();
    const [tonConnectUI] = useTonConnectUI();
    
    const isTonPayment = nextLevelValue === "TON";
    const recipientAddress = 'UQCTA2-c0UMyIApPu9QoAUQcIyFvbe_xBoSU7BDdF12QKiOh'; // Replace with your wallet address

    useEffect(() => {
        setActive(isMaxLevel || (nextLevelValue !== "TON" && score < nextLevelValue))
    }, [isMaxLevel, score, nextLevelValue])

    const handleTonPayment = async () => {
        if (!wallet) {
            tonConnectUI.connectWallet();
            return;
        }

        const transaction = {
            validUntil: Math.floor(Date.now() / 1000) + 60, // 60 sec
            messages: [
                {
                    address: recipientAddress,
                    amount: (1 * 1e9).toString(), // 1 TON in nanotons
                }
            ]
        };

        try {
            const result = await tonConnectUI.sendTransaction(transaction);
            console.log('Transaction result:', result);
            // If transaction is successful, call incrementLevel
            incrementLevel();
        } catch (error) {
            console.error('Transaction failed:', error);
        }
    };

    const handleClick = () => {
        if (isMaxLevel || (!isTonPayment && score < nextLevelValue)) {
            return;
        }

        if (isTonPayment) {
            handleTonPayment();
        } else {
            incrementLevel();
        }
    };

    return (
        <div className="upgrades-container">
            <div className={`task-item upgrade ${isActive ? 'inactive' : ''}`}
                onClick={handleClick}
            >
                <div className='task-item__element'>
                    <div className='task-item__element-icon'>         
                        <span>{icon}</span>
                    </div>
                    <div>
                        <div>
                            <span>{t(title)}</span> • {t('Level')}: {clickerLevel}
                        </div>
                        <div>                   
                            {isTonPayment ? '💎 1 TON' : `🟡 ${nextLevelValue}`}
                        </div>
                    </div>
                </div>
                <div className='button-red'>
                    <svg width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.66406 0.773438L14.625 5.77344C15.0156 6.16406 15.0156 6.75 14.625 7.10156C14.2734 7.49219 13.6875 7.49219 13.2969 7.10156L9.89844 3.70312V13.3125C9.89844 13.8594 9.50781 14.25 8.96094 14.25C8.45312 14.25 8.02344 13.8594 8.02344 13.3125V3.70312L4.625 7.10156C4.27344 7.49219 3.6875 7.49219 3.33594 7.10156C2.94531 6.75 2.94531 6.16406 3.33594 5.77344L8.33594 0.773438C8.6875 0.421875 9.27344 0.421875 9.66406 0.773438ZM2.125 13.9375V17.0625C2.125 17.9609 2.78906 18.625 3.6875 18.625H14.3125C15.1719 18.625 15.875 17.9609 15.875 17.0625V13.9375C15.875 13.4297 16.2656 13 16.8125 13C17.3203 13 17.75 13.4297 17.75 13.9375V17.0625C17.75 18.9766 16.1875 20.5 14.3125 20.5H3.6875C1.77344 20.5 0.25 18.9766 0.25 17.0625V13.9375C0.25 13.4297 0.640625 13 1.1875 13C1.69531 13 2.125 13.4297 2.125 13.9375Z" fill="white"/>
                    </svg>
                </div>
            </div>
        </div>
    );
}

export default Boost;
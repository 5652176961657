import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: {
          '🥈silver': '🥈Silver',
          '🏆golden': '🏆Golden',
          '💎diamond': '💎Diamond',
          'Fren': 'Fren',
          'Boost': 'Boost',
          'Tasks': 'Tasks',
          'Invite friends': 'Invite friends',
          'Share invite link': 'Share invite link',
          'Copied': 'Copied',
          'Copy invite link': 'Copy invite link',
          'No frens yet 😭': 'No frens yet 😭',
          'Hope you have some frens. If they active they will help you earn': 'Hope you have some frens. If they active they will help you earn',
          'Level': 'Level',
          'Clicker': 'Clicker',
          'Energy': 'Energy',
          'Energy Recovery': 'Energy Recovery',
          'Flip': 'Flip',
          'Free daily boosters': 'Free daily boosters',
          'Turbo tap': 'Turbo tap',
          'Recharger': 'Recharger',
          'Start with task': 'Start with task',
          'Get the bonus': 'Get the bonus',
          'Check task': 'Check task',
          'Welcome Bonus': 'Welcome Bonus',
          'Subscribe to Channel': 'Subscribe to Channel',
          'Watch the video and get rewarded': 'Watch the video and get rewarded',
          'Invite 10 friends': 'Invite 10 friends',
          'Claim your reward for your efforts!': 'Claim your reward for your efforts!',
          'More fun together! Invite 10 friends and have fun together.': 'More fun together! Invite 10 friends and have fun together.',
          'Visit Link': 'Visit Link',
          'Watch the video and get rewarded': 'Watch the video and get rewarded',
          'Join our Telegram channel for the latest crypto news and other exciting updates.': 'Join our Telegram channel for the latest crypto news and other exciting updates.',
          'Thank you for joining!': 'Thank you for joining!',
          'Onboarding tasks': 'Onboarding tasks',
          'Invite more friends and earn more coins every time they do!': 'Invite more friends and earn more coins every time they do!',
          'Logo': 'Logo',
          'Hello': 'Hello',
          'Can\'t Find!': 'Can\'t Find!',
          'Join': 'Join',
          'Gotcha': 'Gotcha',
          'This is simple text to introduce our app': 'This is simple text to introduce our app',
          'Let\'s Go!': 'Let\'s Go!',
          'You will get 10000 coins as a premium user!': 'You will get 10000 coins as a premium user!',
          'Congratulations!': 'Congratulations!',
          'Let me in': 'Let me in',
          'Join to CHOO!': 'Join to CHOO!',
          'Privacy policy & Terms': 'Privacy policy & Terms',
        }
      },
      uk: {
        translation: {
          '🥈silver': '🥈Срібло',
          '🏆golden': '🏆Золотий',
          '💎diamond': '💎Платиновий',
          'Fren': 'Друзі',
          'Boost': 'Буст',
          'Tasks': 'Завдання',
          'Invite friends': 'Запросити друзів',
          'Share invite link': 'Поділитися інвайт-лінком',
          'Copied': 'Скопійовано',
          'Copy invite link': 'Скопіювати інвайт-лінк',
          'No frens yet 😭': 'Ще немає друзів 😭',
          'Hope you have some frens. If they active they will help you earn': 'Сподіваюся, що у вас є друзі. Якщо вони активні, вони допоможуть вам заробляти',
          'Invite friends': 'Запросити друзів',
          'Copy invite link': 'Скопіювати інвайт-лінк',
          'Share invite link': 'Поділитися інвайт-лінком',
          'Level': 'Рівень',
          'Clicker': 'Клікер',
          'Energy': 'Енергія',
          'Energy Recovery': 'Відновлення енергії',
          'Flip': 'Перевернути',
          'available': 'доступно',
          'Free daily boosters': 'Безкоштовні денні бусти',
          'Boosters': 'Бусти',
          'Turbo tap': 'Турбо тап',
          'Recharger': 'Перезарядка',
          'Start with task': 'Почати з завданням',
          'Get the bonus': 'Отримати бонус',
          'Check task': 'Перевірити завдання',
          'Welcome Bonus': 'Бонус привітання',
          'Subscribe to Channel': 'Підписатися на канал',
          'Watch the video and get rewarded': 'Переглянути відео і отримати нагороду',
          'Invite 10 friends': 'Запросити 10 друзів',
          'Claim your reward for your efforts!': 'Отримайте нагороду за ваші зусилля!',
          'More fun together! Invite 10 friends and have fun together.': 'Більше веселого разом! Запросіть 10 друзів і насолоджуйтесь разом.',
          'Visit Link': 'Перейти за посиланням',
          'Join our Telegram channel for the latest crypto news and other exciting updates.': 'Приєднуйтесь до нашого Telegram-каналу для останніх крипто-новинок та інших захопливих оновлень.',
          'Thank you for joining!': 'Дякуємо за підписку!',
          'Onboarding tasks': 'Завдання привітання',
          'Invite more friends and earn more coins every time they do!': 'Запросіть більше друзів і заробіть більше монет кожен раз, коли вони це зроблять!',
          'Logo': 'Лого',
          'Hello': 'Привіт',
          'Can\'t Find!': 'Не знайдено!',
          'Join': 'Приєднатися',
          'Gotcha': 'Чудово!',
          'This is simple text to introduce our app': 'Цей текст для введення нашого додатку',
          'Let\'s Go!': 'Давайте поїдемо!',
          'You will get 10000 coins as a premium user!': 'Ви отримаєте 10000 монет як преміум користувач!',
          'Congratulations!': 'Вітаємо!',
          'Let me in': 'Дозволь мені ввійти',
          'Join to CHOO!': 'Приєднатися!',
          'Privacy policy & Terms': 'Політика конфіденційності & Умови',
        }
      },
      ru: {
        translation: {
          '🥈silver': '🥈Серебро',
          '🏆golden': '🏆Золото',
          '💎diamond': '💎Платина',
          'Fren': 'Друзья',
          'Boost': 'Буст',
          'Tasks': 'Завдання',
          'Invite friends': 'Пригласить друзей',
          'Share invite link': 'Поделиться ссылкой для приглашения',
          'Copied': 'Скопировано',
          'Copy invite link': 'Скопировать ссылку для приглашения',
          'No frens yet 😭': 'Еще нет друзей 😭',
          'Hope you have some frens. If they active they will help you earn': 'Надеюсь, у вас есть друзья. Если они активны, они помогут вам зарабатывать',
          'Level': 'Уровень',
          'Clicker': 'Кликер',
          'Energy': 'Энергия',
          'Energy Recovery': 'Восстановление энергии',
          'Flip': 'Перевернуть',
          'available': 'доступно',
          'Free daily boosters': 'Бесплатные денные бусты',
          'Boosters': 'Бусты',
          'Turbo tap': 'Турбо тап',
          'Recharger': 'Перезарядка',
          'Start with task': 'Начать с заданием',
          'Get the bonus': 'Получить бонус',
          'Check task': 'Проверить задание',
          'Welcome Bonus': 'Бонус приветствия',
          'Subscribe to Channel': 'Подписаться на канал',
          'Watch the video and get rewarded': 'Посмотреть видео и получить награду',
          'Invite 10 friends': 'Пригласить 10 друзей',
          'Claim your reward for your efforts!': 'Получите награду за ваши усилия!',
          'More fun together! Invite 10 friends and have fun together.': 'Больше веселого вместе! Пригласите 10 друзей и насладитесь вместе.',
          'Visit Link': 'Перейти по ссылке',
          'Join our Telegram channel for the latest crypto news and other exciting updates.': 'Присоединяйтесь к нашему Telegram-каналу для последних крипто-новостей и других захватывающих обновлений.',
          'Thank you for joining!': 'Спасибо за подписку!',
          'Onboarding tasks': 'Задание приветствия',
          'Invite more friends and earn more coins every time they do!': 'Пригласите больше друзей и заработайте больше монет каждый раз, когда они это сделают!',
          'Logo': 'Лого',
          'Hello': 'Привет',
          'Can\'t Find!': 'Не найдено!',
          'Join': 'Присоединиться',
          'Gotcha': 'Отлично!',
          'This is simple text to introduce our app': 'Этот текст для введения нашего приложения',
          'Let\'s Go!': 'Давайте поедем!',
          'You will get 10000 coins as a premium user!': 'Вы получите 10000 монет как премиум пользователь!',
          'Congratulations!': 'Поздравляем!',
          'Let me in': 'Позволь мне войти',
          'Join to CHOO!': 'Присоединиться!',
          'Privacy policy & Terms': 'Политика конфиденциальности & Условия',
        }
      }
    },
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    }
  });

export default i18n;

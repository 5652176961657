import {useNavigate} from 'react-router-dom';
import {initBackButton} from '@telegram-apps/sdk-react';
import React, {useEffect, useState} from "react";
import clanCoinIcon from "../../assets/gold-coin.svg"
import searchIcon from '../../assets/search.svg'
import './Yards.css'
function Yards() {

    
    const [backButton] = initBackButton();
    const navigate = useNavigate();
    const [createPage, setCreatePage] = useState(true)

    const list = [
        {
            name: 'Хуевая Одесса',
            score: 12000,
            members: 1000,
            img: 'https://placehold.jp/3d4070/ffffff/150x150.png'
        },
        {
            name: 'Киев',
            score: 11000,
            members: 900,
            img: 'https://placehold.jp/f51414/ffffff/150x150.png'
        },
        {
            name: 'Типовий Львів',
            score: 10000,
            members: 800,
            img: 'https://placehold.jp/3d4070/ffffff/150x150.png'
        },
        {
            name: 'Лачен пише',
            score: 9000,
            members: 700,
            img: 'https://placehold.jp/f51414/ffffff/150x150.png'
        },
        {
            name: 'Zelenskiy Official',
            score: 8000,
            members: 600,
            img: 'https://placehold.jp/3d4070/ffffff/150x150.png'
        },
        {
            name: 'Киев2',
            score: 7000,
            members: 500,
            img: 'https://placehold.jp/f51414/ffffff/150x150.png'
        },
        {
            name: 'Типовий Львів2',
            score: 6000,
            members: 400,
            img: 'https://placehold.jp/3d4070/ffffff/150x150.png'
        },
        {
            name: 'Лачен пише2',
            score: 5000,
            members: 700,
            img: 'https://placehold.jp/f51414/ffffff/150x150.png'
        }
    ]

    backButton.on('click', () => {
        backButton.hide();
        navigate('/');
    });
    useEffect(() => {
        backButton.show();
    }, []);

    return (
        <div className="page">
            <div className="yards-page">
                {createPage ? 
                <div className='yards-page__create'>
                    <div className='yards-page__create-title'>
                        Create your yard
                    </div>

                    <div className='yards-page__pic-upload'>
                        <div className='yards-page__pic-upload-icon'>
                            +
                        </div>
                        <div className='yards-page__pic-upload-text'>
                            Upload yard picture
                        </div>                  
                    </div>
                    <div className='yards-page__create-name'>
                        <label>
                            <span>Yard name</span>
                            <input placeholder='example@gmail.com' type="text" />
                        </label>
                    </div>
                </div>
                :
                <div className='yards-page__search'>
                <label className='yards-search'>
                    <div className='yards-search__img'>
                        <img src={searchIcon} alt="search icon" />
                    </div>
                    <input placeholder='Search' className='yards-search__input' type="text" name="search"  />
                </label>

                <div className='yards-list'>
                    <div className='yards-container'>
                    {list.map((item, i) => (
                        <div className='yards-search__item'>
                            <div className='yards-search__item-content'>
                                <div className='yards-search__item-img'>
                                    <img src={item.img} alt="avatar" />
                                </div>
                                <div className='yards-search__item-stats'>
                                    <div className='yards-search__item-name'>{i + 1}. {item.name}</div>
                                    <div className='yards-search__item-score'>
                                        <span>
                                            <img src={clanCoinIcon} alt="coin icon" />
                                            {item.score}
                                        </span>
                                        {' '}
                                        <span className='yards-search__item-score-members'> • {item.members} {item.members > 1 ? 'members' : 'member'}</span>
                                    </div>

                                </div>
                            </div>
                            <div className='yards-search__item-medal'>
                                {i === 0 && (
                                    <span>🥇</span>
                                )}
                                {i === 1 && (
                                    <span>🥈</span>
                                )}
                                {i === 2 && (
                                    <span>🥉</span>
                                )}
                            </div>
                        </div>
                    ))}
                    </div>
                    
                </div>
                </div>
                }
               

                
                

                <div className='yards-bottom-panel'>
                    <button className="button-red">Create yard</button>

                </div>
            </div>
            
        </div>
    );
}

export default Yards;
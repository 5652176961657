import { TonConnectButton, useTonWallet, useTonConnectUI } from '@tonconnect/ui-react';
import {
  Avatar,
  Cell,
  List,
  Navigation,
  Placeholder,
  Section,
  Text,
  Title,
  Button,
  Input,
} from '@telegram-apps/telegram-ui';
import Menu from "../../components/Menu";
import { DisplayData } from '../../components/DisplayData/DisplayData.jsx';
import React, { useState } from 'react';

import './TONConnectPage.css';

/**
 * @returns {JSX.Element}
 */
export function TONConnectPage() {
  const wallet = useTonWallet();
  const [tonConnectUI] = useTonConnectUI();
  const [recipientAddress, setRecipientAddress] = useState('UQCTA2-c0UMyIApPu9QoAUQcIyFvbe_xBoSU7BDdF12QKiOh');
  const [amount, setAmount] = useState('0.1');
  const [transactionStatus, setTransactionStatus] = useState('');

  const sendTransaction = async () => {
    if (!wallet) {
      setTransactionStatus('Please connect your wallet first.');
      return;
    }

    const transaction = {
      validUntil: Math.floor(Date.now() / 1000) + 60, // 60 sec
      messages: [
        {
          address: recipientAddress,
          amount: (parseFloat(amount) * 1e9).toString(), // Convert TON to nanoTON
        }
      ]
    };

    try {
      setTransactionStatus('Sending transaction...');
      const result = await tonConnectUI.sendTransaction(transaction);
      console.log('Transaction result:', result);
      setTransactionStatus('Transaction sent successfully!');
    } catch (error) {
      console.error('Transaction failed:', error);
      setTransactionStatus('Transaction failed. Please try again.');
    }
  };

  if (!wallet) {
    return (
      <div className="page">
        <div className="friends-page">
          <Placeholder
            className='ton-connect-page__placeholder'
            header='TON Connect'
            description={
              <>
                <Text>
                  To display the data related to the TON Connect, it is required to connect your wallet
                </Text>
                <TonConnectButton className='ton-connect-page__button'/>
              </>
            }
          />
        </div>
        <div className="menu-container">
          <Menu/>
        </div>
      </div>
    );
  }

  return (
    <div className="page">
      <div className="wallet-page">
        <List>
          {'imageUrl' in wallet && (
            <div className=''>
              <Section>
                <Cell
                  before={
                    <Avatar src={wallet.imageUrl} alt='Provider logo' width={10} height={10}/>
                  }
                  after={<Navigation>About wallet</Navigation>}
                  subtitle={wallet.appName}
                  onClick={(e) => {
                    e.preventDefault();
                    // If WebApp is not available, you may want to use window.open or another method
                    // WebApp.openLink(wallet.aboutUrl);
                    window.open(wallet.aboutUrl, '_blank');
                  }}
                >
                  <Title level='3'>{wallet.name}</Title>
                </Cell>
              </Section>
              <TonConnectButton className='ton-connect-page__button-connected'/>
            </div>
          )}
          <Section>
            <Title level='3'>Send Transaction</Title>
            <Input
              placeholder="Recipient Address"
              value={recipientAddress}
              onChange={(e) => setRecipientAddress(e.target.value)}
            />
            <Input
              placeholder="Amount (in TON)"
              type="number"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
            />
            <Button onClick={sendTransaction}>Send Transaction</Button>
            {transactionStatus && <p>{transactionStatus}</p>}
          </Section>
          <DisplayData
            header='Account'
            rows={[
              { title: 'Address', value: wallet.account.address },
              { title: 'Chain', value: wallet.account.chain },
              { title: 'Public Key', value: wallet.account.publicKey },
            ]}
          />
          <DisplayData
            header='Device'
            rows={[
              { title: 'App Name', value: wallet.device.appName },
              { title: 'App Version', value: wallet.device.appVersion },
              { title: 'Max Protocol Version', value: wallet.device.maxProtocolVersion },
              { title: 'Platform', value: wallet.device.platform },
              {
                title: 'Features',
                value: wallet.device.features
                  .map(f => typeof f === 'object' ? f.name : undefined)
                  .filter(v => v)
                  .join(', '),
              },
            ]}
          />
        </List>
      </div>
      <div className="menu-container">
        <Menu/>
      </div>
    </div>
  );
}

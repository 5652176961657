import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {initBackButton, initUtils, useInitData} from "@telegram-apps/sdk-react";
import {useNavigate} from "react-router-dom";
import Modal from "../../components/modal/Modal";
import useAppContext from '../../state/useAppContext';
import './Tasks.css'
import { useTranslation } from 'react-i18next';
import CheckingAnimation from '../../components/CheckingAnimation/CheckingAnimation';
import Popup from '../../components/Popup/Popup';
import TaskItem from '../../components/TaskItem/TaskItem';
import leagues from '../../data/leagues';
const API_URL = process.env.REACT_APP_API_URL;
const POPUP_TASK_TYPES = ['subscribe_channel', 'visit_link'];
const WITHOUT_POPUP_TASK_TYPES = ['welcome_bonus', 'invite_friends', 'league'];

function Tasks() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const utils = initUtils();
    const [backButton] = initBackButton();
    const [popupMessage, setPopupMessage] = useState('');
    const { user, actions} = useAppContext();
    const { modifyScore } = actions;
    const initData = useInitData();
    const _id = user ? user._id : null;
    const userId = initData.user.id;
    const [tasks, setTasks] = useState([]);
    const [activeTaskId, setActiveTaskId] = useState(null);
    const [checking, setChecking] = useState(false);
    const onboardingTasks = ['welcome_bonus', 'invite_friends', 'subscribe_channel'];
    const specialTasks = ['league', 'visit_link'];

    const getReferrers = async (userId) => {
        const res = await axios.get(`${API_URL}/users/get-referrers/${userId}`);
        return res.data;
    }

    const closeModal = () => {
        setActiveTaskId(null);
    };

    backButton.on('click', () => {
        backButton.hide();
        navigate('/');
    });

    useEffect(() => {
        backButton.show();
    }, [backButton]);

    useEffect(() => {
        const fetchTasks = async () => {
            if (_id) {
                const result = await axios.get(`${API_URL}/tasks/${user._id}`);
                setTasks(result.data.tasks);
            }
        };
        fetchTasks();
    }, [_id, user]);

    const startTask = async (task) => {
        try {
            const response = await axios.post(`${API_URL}/tasks/start`, {taskId: task._id, userId: _id});
            if (response.data.success) {
                // Update task state to reflect that it has started
                setTasks(tasks.map(t => t._id === task._id ? {...t, isStarted: true} : t));
            } else {
                console.error('Error starting task:', response.data.message);
            }
        } catch (error) {
            console.error('Error starting task:', error);
        }
    };

    const startAndCompleteTask = async (task) => {
        try {
            const response = await axios.post(`${API_URL}/tasks/start-and-complete`, {taskId: task._id, userId: _id});
            if (response.data.success) {
                // Update task state to reflect that it has started
                setTasks(tasks.map(t => t._id === task._id ? {...t, isStarted: true, isCompleted: true} : t));
                return true;
            } else {
                console.error('Error starting or completing task:', response.data.message);
            }
        } catch (error) {
            console.error('Error starting or completing task:', error);
            return false;
        }
    };

    const getTaskAction = async (task) => {
        if ((task.type === 'welcome_bonus' || task.type === 'league') && !task.isStarted) {
            startTask(task);
            return;
        }

        if (task.type === 'invite_friends') {
            const referrers = await getReferrers(userId);
            if (referrers.length >= 10) {
                completeTask(task);
                return;
            } else {
                return;
            }
        }

        if (!task.isStarted) {
            startTask(task);
        }

        if (task.type === 'subscribe_channel') {
            utils.openTelegramLink(task.url);
        }

        if (task.type === 'visit_link') {
            utils.openLink(task.url);
        }

        setActiveTaskId(null);
    }

    const completeTask = async (task) => {

        if (task.type === 'subscribe_channel') {
            const subscriptionCheck = await axios.post(`${API_URL}/tasks/check-subscription`, {userId: userId});

            if (!subscriptionCheck.data.isSubscribed) {
                alert('You are not subscribed to the channel!');
                return;
            }
        }
        if (task.type === 'welcome_bonus' || task.type === 'league') {
            startTask(task);
        }

        const response = await axios.post(`${API_URL}/tasks/complete`, {taskId: task._id, userId: _id});
        if (response.data.success) {
            modifyScore(task.coins);
            setTasks(tasks.map(t => t._id === task._id ? {...t, isCompleted: true} : t));
        }

        setActiveTaskId(null);
    };


    const handleSpecialTask = async (task) => {
        let errorMessage = 'Task not Completed';
        setChecking(true);
        try {
            let result;
            switch (task.type) {
                case 'welcome_bonus':
                    result = await startAndCompleteTask(task);
                    console.log('result', result)
                    break;
                case 'invite_friends':
                    const referrers = await getReferrers(userId);
                    result = referrers.length >= 10;
                    errorMessage = 'You need to invite ' + (10 - referrers.length) + ' more friends to complete this task';
                    break;
                case 'subscribe_channel':
                    // utils.openTelegramLink(task.url);
                    // const subscriptionCheck = await axios.post(`${API_URL}/tasks/check-subscription`, {userId: userId});
                    // result = subscriptionCheck.data.isSubscribed;
                    break;
                case 'league':
                    const league = user.league;
                    const leagueScore = leagues[league - 1].score;
                    result = leagueScore >= task.coins;
                    if (!result) {
                        errorMessage = 'You need to earn ' + (task.coins - user.score) + ' more coins to complete this task';
                    }
                    break;
                default:
                    result = false;
            }

            if (result) {
                await completeTask(task);
                setTasks(tasks.map(t => t._id === task._id ? {...t, isCompleted: true} : t));
            } else {
                // Replace the alert with this:
                setPopupMessage(t(errorMessage));
            }
        } catch (error) {
            console.error('Error handling special task:', error);
            // Replace the alert with this:
            setPopupMessage(t(errorMessage));
        } finally {
            setChecking(false);
        }
    };

    const handleTaskClick = (task) => {
        if (WITHOUT_POPUP_TASK_TYPES.includes(task.type)) {
            handleSpecialTask(task);
        }
        if (POPUP_TASK_TYPES.includes(task.type)) {
            setActiveTaskId(activeTaskId !== task._id && !task.isCompleted && task._id);
        }
    };

    const activeTask = tasks.find(t => t._id === activeTaskId);

    useEffect(() => {
        if (popupMessage) {
            const timer = setTimeout(() => {
                setPopupMessage('');
            }, 1000);
            return () => clearTimeout(timer);
        }
    }, [popupMessage]);

    return (
        <div className="page tasks-page-scroll-container">
            {checking && <CheckingAnimation />}
            {popupMessage && <Popup message={popupMessage} />}
            <div className="tasks-page">
                <h2 className="tasks-header">{t('Onboarding tasks')}</h2>
                <ul className="task-list">
                    {tasks.filter(task => onboardingTasks.includes(task.type)).map(task => (
                        <TaskItem
                            key={task._id}
                            task={task}
                            onTaskClick={handleTaskClick}
                        />
                    ))}
                </ul>
                <h2 className="tasks-header">{t('Explore & Earn')}</h2>
                <ul className="task-list">
                    {tasks.filter(task => specialTasks.includes(task.type)).map(task => (
                        <TaskItem
                            key={task._id}
                            task={task}
                            onTaskClick={handleTaskClick}
                        />
                    ))}
                </ul>

                {activeTaskId && POPUP_TASK_TYPES.includes(activeTask?.type) && (
                    <Modal
                        isOpen
                        onClose={closeModal}
                        title={activeTask?.name}
                    >
                        {activeTask?.description && <p>{t(activeTask.description)}</p>}

                        {activeTask?.isStarted && activeTask?.type === 'subscribe_channel' && (
                            <button className="button-red" onClick={() => utils.openTelegramLink(activeTask.url)}>
                                {t('Subscribe to channel')}
                            </button>
                        )}

                        {activeTask?.isStarted ? (
                            <button className="button-red" onClick={() => completeTask(activeTask)}>
                                {t('Check task')}
                            </button>
                        ) : (
                            <button className="button-red" onClick={() => getTaskAction(activeTask)}>
                                {t('Start with task')}
                            </button>
                        )}
                    </Modal>
                )}
            </div>
        </div>
    );
}

export default Tasks;

import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export const fetchUser = async (userId) => {
    try {
        const response = await axios.get(`${API_URL}/users/get-user/${userId}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching user:', error);
        throw error;
    }
};

export const updateUser = async (userData) => {
    try {
        const response = await axios.post(`${API_URL}/users/update-user`, userData);
        return response.data;
    } catch (error) {
        console.error('Error updating user:', error);
        throw error;
    }
};

export const createUser = async (userData) => {
    try {
        const response = await axios.post(`${API_URL}/users/create-user`, userData);
        return response.data;
    } catch (error) {
        console.error('Error updating user:', error);
        throw error;
    }
};
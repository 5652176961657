import React from "react";
import coinIcon from "../assets/coin.png";

function Coin({energy, clickLevel, handleMultiTouch, coinRef}) {
    return (
        <div className="coin-wrapper">
          <img
                className="coin"
                ref={coinRef}
                src={coinIcon}
                alt="coin"
                onTouchStart={handleMultiTouch}
                style={{
                    cursor: energy < clickLevel ? 'not-allowed' : 'pointer',
                    opacity: energy < clickLevel ? 0.5 : 1,
                    width: '100%',
                    height: 'auto',
                    position: 'relative',
                }}
            />
        </div>
    );
}

export default Coin;
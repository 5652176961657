import React, {useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import useAppContext from '../../state/useAppContext';
import { useTranslation } from 'react-i18next';


function Congratulations() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { user } = useAppContext();

    const handleButton = () => {
        navigate('/');
    }

    if (!user) {
        return <div>Loading...</div>
    }

    return (
        <div className="page">
            <div className="congrats-container">
                <div className="congrats-text">
                    <span role="img" aria-label="trophy">🏆</span>
                    <p>{t('Congratulations!')}</p>
                </div>
                <div className="coins-text">
                    <p>{user.score} coins</p>
                </div>
                <button onClick={handleButton} className="join-button">{t('Let me in')}</button>
            </div>
        </div>
    );
}

export default Congratulations;

// Modal.js

import React, { useEffect, useState } from 'react';
import './Modal.css'; // Import your CSS styles for the modal
import { useTranslation } from 'react-i18next';

const Modal = ({ isOpen, onClose, title, start, children }) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setShowModal(true); // Show modal content when `isOpen` is true
    } else {
      setShowModal(false); // Hide modal content when `isOpen` is false
    }
  }, [isOpen]);

  const closeHandle = () => {
    setShowModal(false);
    setTimeout(() => {
      onClose()
    }, 200)
  }

  return (
    <div className={`modal-overlay ${isOpen ? 'show' : ''}`}>
      <div className={`modal-content ${showModal ? 'show' : ''}`}>
        <button className="modal-close-button" onClick={closeHandle}>
          &times;
        </button>
        <h2 className="modal-title">{t(title)}</h2>
        <div className="modal-body">{children}</div>
      </div>
    </div>
  );
};

export default Modal;

import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import useAppContext from '../../state/useAppContext';
import { useTranslation } from 'react-i18next';

function UserStartStats() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { user, actions } = useAppContext();

    if (!user) {
        return <div>Loading...</div>
    }

    const handleButton = async () => {
        if (user.isPremium && user.score === 0) {
            await actions.updateUser({ id: user.id, score: 10000 });
        }
        navigate('/congratulations');
    };

    return (
        <div className="page">
            <div className="user-start-stats-container">

                <div className="welcome-text">
                    <p>{t('Hello')}, {user.firstName} {user.lastName}</p>
                </div>
                <div className="stats-text">
                    {t('We prepared something special for you. Here is info and reward for you!')}
                </div>

                <div className="rewards-container">
                    {user.isPremium && (
                        <div className="reward-item">
                            <div className="reward-item-text">Premium Account</div>
                            <div className="reward-item-status">✅</div>
                        </div>
                    )}

                    <div className="reward-item">
                        <div className="reward-item-text">Account age</div>
                        <div className="reward-item-status">5 years</div>
                    </div>
                </div>

                <button onClick={handleButton} className="button-red">{t('Continue')}</button>
            </div>
        </div>
    );
}

export default UserStartStats;

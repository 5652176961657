export const initialState = {
    user: null,
    tasks: [],
    boosts: [],
    friends: [],
    isLoading: false,
    error: null,
    isNewUser: false,
};

export function appReducer(state, action) {
    switch (action.type) {
        case 'SET_USER':
            return { ...state, user: action.payload, isLoading: false };
        case 'UPDATE_USER':
            return { ...state, user: { ...state.user, ...action.payload } };
        case 'MODIFY_ENERGY':
            return { ...state, user: { ...state.user, energy: state.user.energy + action.payload } };
        case 'MODIFY_SCORE':
            return { ...state, user: { ...state.user, score: state.user.score + action.payload } };
        case 'SET_ENERGY':
            return { ...state, user: { ...state.user, energy: action.payload } };
        case 'SET_TASKS':
            return { ...state, tasks: action.payload };
        case 'SET_BOOSTS':
            return { ...state, boosts: action.payload };
        case 'SET_FRIENDS':
            return { ...state, friends: action.payload };
        case 'SET_LOADING':
            return { ...state, isLoading: action.payload };
        case 'SET_ERROR':
            return { ...state, error: action.payload, isLoading: false };
        case 'SET_IS_NEW_USER':
            return { ...state, isNewUser: action.payload };
        case 'SET_ENERGY_LEVEL':
            return { ...state, user: { ...state.user, energyLevel: action.payload } };
        case 'SET_CLICKER_LEVEL':
            return { ...state, user: { ...state.user, clickLevel: action.payload } };
        case 'SET_ENERGY_RECOVERY_LEVEL':
            return { ...state, user: { ...state.user, energyRecoveryLevel: action.payload } };
        case 'SET_FLIP_LEVEL':
            return { ...state, user: { ...state.user, flipLevel: action.payload } };
        default:
            return state;
    }
}

import {useNavigate} from 'react-router-dom';
import {initBackButton} from '@telegram-apps/sdk-react';
import React, {useEffect, useState} from "react";
import clanIcon from "../../assets/clannameicon.png"
import clanCoinIcon from "../../assets/gold-coin.svg"

import './CurrentYard.css'
function CurrentYard() {

    
    const [backButton] = initBackButton();
    const navigate = useNavigate();
    const [filteredList, setFilteredList] = useState(null)
    const [currentUser, setCurrentUser] = useState(null)
    const [currentUserIndex, setCurrentUserIndex] = useState(null)
    const [userList, setUserList] = useState([
        {
            name: 'Name1',
            score: 12000000,
            current: false,
            img: 'https://placehold.jp/3d4070/ffffff/150x150.png'
        },
        {
            name: 'Name2',
            score: 11000000,
            current: true,
            img: 'https://placehold.jp/f51414/ffffff/150x150.png'
        },
        {
            name: 'Name3',
            score: 10000000,
            current: false,
            img: 'https://placehold.jp/3d4070/ffffff/150x150.png'
        },
        {
            name: 'Name4',
            score: 9000000,
            current: false,
            img: 'https://placehold.jp/f51414/ffffff/150x150.png'
        },
        {
            name: 'Name5',
            score: 8000000,
            current: false,
            img: 'https://placehold.jp/3d4070/ffffff/150x150.png'
        },
        {
            name: 'Name6',
            score: 800000,
            current: false,
            img: 'https://placehold.jp/f51414/ffffff/150x150.png'
        },
        {
            name: 'Name7',
            score: 700000,
            current: false,
            img: 'https://placehold.jp/3d4070/ffffff/150x150.png'
        },
        {
            name: 'Name8',
            score: 600000,
            current: false,
            img: 'https://placehold.jp/f51414/ffffff/150x150.png'
        },
        {
            name: 'Name9',
            score: 400000,
            current: false,
            img: 'https://placehold.jp/3d4070/ffffff/150x150.png'
        },
        {
            name: 'Name10',
            score: 500000,
            current: false,
            img: 'https://placehold.jp/f51414/ffffff/150x150.png'
        },
    ])

    useEffect(() => {
        if (userList) {
            const sortedUsers = userList.sort((a, b) => b.score - a.score);
            const current =  userList.filter(user => user.current === true)
            if (current.length > 0) {
                setCurrentUser(current)
            }
            setFilteredList(sortedUsers)
        }
    }, [userList])

    useEffect(() => {
        if (filteredList) {
            const i = filteredList.findIndex(user => user.current === true);
            setCurrentUserIndex(i)
        }
    }, [filteredList])

    function formatScore(score) {
        if (score >= 1_000_000) {
            return `${(score / 1_000_000).toFixed(0)}Mil`; 
        } else if (score >= 1_000) {
            return `${(score / 1_000).toFixed(0)}K`; 
        } else {
            return `${score}`;
        }
    }

    backButton.on('click', () => {
        backButton.hide();
        navigate('/');
    });
    useEffect(() => {
        backButton.show();
    }, []);

    return (
        <div className="page">
            <div className="current-yard-page">
                <div className='yard-name'>
                    <div className='yard-name__img'>
                        <img src={clanIcon} alt="clan icon" />
                    </div>
                    <div className='yard-name__name'>
                        <span>Хуевая Одесса</span>
                    </div>
                    <div className='yard-name__item-stats'>
                        <div className='yard-name__item-score'>
                            <span>
                                <img src={clanCoinIcon} alt="coin icon" />
                                19,000,210,192
                            </span>
                            {' '}
                            <span className='yard-name__item-score-members'> • 12K members</span>
                        </div>
                    </div>
                    <button className='button-red'>
                        <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.21094 9.875H5.11328L5.60547 8.75391C5.82422 9.24609 6.17969 9.68359 6.64453 9.98438L6.91797 10.1484L6.58984 10.8594C6.37109 11.3242 5.90625 11.625 5.38672 11.625H3.88281C3.47266 13.2383 2.87109 14.25 2.1875 14.25C0.957031 14.25 0 11.1328 0 7.25C0 3.39453 0.957031 0.25 2.1875 0.25C3.0625 0.25 3.85547 2 4.18359 4.46094L4.8125 3.83203C5.41406 3.23047 6.23438 2.875 7.08203 2.875C7.41016 2.875 7.71094 2.92969 8.01172 3.03906L9.35156 3.44922C10.3359 3.75 11.1562 4.48828 11.5391 5.44531L11.7305 5.85547C11.8672 6.18359 12.168 6.375 12.5234 6.375H13.125C13.5898 6.375 14 6.78516 14 7.25C14 7.74219 13.5898 8.125 13.125 8.125H12.5234C11.457 8.125 10.5273 7.49609 10.1172 6.51172L9.95312 6.18359L9.13281 8.42578L10.6641 9.41016C11.1562 9.71094 11.4023 10.3125 11.2383 10.8867L10.4453 13.6211C10.3086 14.0859 9.84375 14.3594 9.37891 14.2227C8.91406 14.0859 8.64062 13.6211 8.77734 13.1562L9.46094 10.7227L7.10938 9.24609C6.42578 8.80859 6.125 7.96094 6.37109 7.19531L7.21875 4.65234C7.19141 4.65234 7.13672 4.625 7.08203 4.625C6.69922 4.625 6.31641 4.78906 6.04297 5.0625L5.41406 5.69141C5.11328 5.99219 4.64844 6.01953 4.32031 5.80078C4.34766 6.26562 4.375 6.75781 4.375 7.25C4.375 8.17969 4.32031 9.08203 4.21094 9.875ZM10.0625 2.875C9.57031 2.875 9.16016 2.62891 8.91406 2.21875C8.66797 1.83594 8.66797 1.31641 8.91406 0.90625C9.16016 0.523438 9.57031 0.25 10.0625 0.25C10.5273 0.25 10.9375 0.523438 11.1836 0.90625C11.4297 1.31641 11.4297 1.83594 11.1836 2.21875C10.9375 2.62891 10.5273 2.875 10.0625 2.875Z" fill="white"/>
                        </svg>
                        <span>Leave yard</span>
                    </button>
                </div>
                <div className={`current-yard-list ${currentUser ? 'with-current' : ''}`}>
                    <div className={`current-yard-container ${currentUser ? 'with-current' : ''}`}>
                    {filteredList && filteredList.map((item, i) => (
                        <div key={i} className='current-yard__item'>
                            <div className='current-yard__item-content'>
                                <div className='current-yard__item-img'>
                                    <img src={item.img} alt="avatar" />
                                </div>
                                <div className='current-yard__item-stats'>
                                    
                                    <div className='current-yard__item-medal'>
                                    {i === 0 && (
                                    <span>🥇</span>
                                    )}
                                    {i === 1 && (
                                        <span>🥈</span>
                                    )}
                                    {i === 2 && (
                                        <span>🥉</span>
                                    )}
                                    {i > 2 && (
                                        <span>{i + 1}.</span>
                                    )}
                                    </div>

                                    <div className='current-yard__item-name'>{item.name}</div>
                                    

                                </div>
                            </div>
                            <div className='current-yard__item-score'>
                                <img src={clanCoinIcon} alt="coin icon" />
                                +{formatScore(item.score)}
                            </div>
                        </div>
                    ))}
                    </div>
                    
                </div>
                {currentUser && 
                    <div className='yards-page__bottom'>
                        <div className='current-yard__item'>
                            <div className='current-yard__item-content'>
                                <div className='current-yard__item-img'>
                                    <img src={currentUser?.[0]?.img} alt="avatar" />
                                </div>
                                <div className='current-yard__item-stats'>
                                    
                                    <div className='current-yard__item-medal'>
                                    {currentUserIndex === 0 && (
                                    <span>🥇</span>
                                    )}
                                    {currentUserIndex === 1 && (
                                        <span>🥈</span>
                                    )}
                                    {currentUserIndex === 2 && (
                                        <span>🥉</span>
                                    )}
                                    {currentUserIndex > 2 && (
                                        <span>{currentUserIndex + 1}.</span>
                                    )}
                                    </div>

                                    <div className='current-yard__item-name'>{'You'}</div>
                                    

                                </div>
                            </div>
                            <div className='current-yard__item-score'>
                                <img src={clanCoinIcon} alt="coin icon" />
                                +{formatScore(currentUser?.[0]?.score)}
                            </div>
                        </div>
                    </div>
                
                }
                
            </div>
            
        </div>
    );
}

export default CurrentYard;
import './App.css';
import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom';
import {SDKProvider} from '@telegram-apps/sdk-react';
import {routes} from "./navigation/routes";
import React, { useMemo, useState, useEffect } from "react";
import {TimerProvider} from './context/TimerContext';
import { AppProvider } from './state/AppProvider';
import { TonConnectUIProvider } from '@tonconnect/ui-react';
import { AppRoot } from '@telegram-apps/telegram-ui';

function App() {
    const manifestUrl = useMemo(() => {
        return new URL('tonconnect-manifest.json', window.location.href).toString();
    }, []);

    const [isSmallScreen, setIsSmallScreen] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerHeight < 100 || window.innerWidth < 100);
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <AppRoot> {/* Wrap everything with AppRoot */}
            <TonConnectUIProvider manifestUrl={manifestUrl}>
                <SDKProvider acceptCustomStyles debug={process.env.REACT_APP_ENV === 'development'}>
                    <BrowserRouter>
                        <AppProvider>
                            <TimerProvider>
                                <div className="App">
                                    <div id="wrap">
                                        <div id="content">
                                            <div className={`container ${isSmallScreen ? 'small-screen' : ''}`}>
                                                <Routes>
                                                    {routes.map((route) => <Route key={route.path} {...route} />)}
                                                    <Route path='*' element={<Navigate to='/'/>}/>
                                                </Routes>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </TimerProvider>
                        </AppProvider>
                    </BrowserRouter>
                </SDKProvider>
            </TonConnectUIProvider>
        </AppRoot>
    );
}

export default App;

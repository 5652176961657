import React from 'react';
import { useTranslation } from 'react-i18next';
import './TaskItem.css';

const TaskItem = ({ task, onTaskClick }) => {
    const { t } = useTranslation();

    return (
        <li 
            onClick={() => !task.isCompleted ? onTaskClick(task) : null}
            className={`task-item ${task.isCompleted ? 'inactive' : ''}`}
        >
            <div className='task-item__element'>
                <div className='task-item__element-icon'>
                    <div className="task-icon">🎉</div>
                </div>
                <div>
                    <div className="task-title">{t(task.name)}</div>
                    <div className="task-coins">🟡 +{task.coins}</div>
                </div>
            </div>
            <button className="task-action">
                <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.53125 5.46875C6.8125 5.78125 6.8125 6.25 6.53125 6.53125L1.53125 11.5312C1.21875 11.8438 0.75 11.8438 0.46875 11.5312C0.15625 11.25 0.15625 10.7812 0.46875 10.5L4.9375 6.03125L0.46875 1.53125C0.15625 1.25 0.15625 0.78125 0.46875 0.5C0.75 0.1875 1.21875 0.1875 1.5 0.5L6.53125 5.46875Z" fill="white"/>
                </svg>
            </button>
        </li>
    );
};

export default TaskItem; 
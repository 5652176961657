import React, { useEffect } from "react";

function Popups({ popups, removePopup }) {
    return (
        <>
            {popups.map((popup) => (
                <PopupItem
                    key={popup.id}
                    popup={popup}
                    removePopup={removePopup}
                />
            ))}
        </>
    );
}

function PopupItem({ popup, removePopup }) {
    console.log(popup)
    useEffect(() => {
        const timer = setTimeout(() => {
            removePopup(popup.id); 
        }, 1000);

        return () => clearTimeout(timer); 
    }, [popup.id, removePopup]);

    return (
        <div
            className="points-popup"
            style={{
                position: 'absolute',
                top: `${popup.position.y}px`,
                left: `${popup.position.x}px`,
                pointerEvents: 'none',
            }}
        >
            +{popup.points}
        </div>
    );
}

export default Popups;
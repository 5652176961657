import { useNavigate } from 'react-router-dom';
import useAppContext from '../../state/useAppContext';
import { useTranslation } from 'react-i18next';
import coinIcon from '../../assets/coin.svg';

function Welcome() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { actions, telegram } = useAppContext();
    const tgUser = telegram.user;
    console.log('tgUser', tgUser)

    const handleJoin = async () => {
        await actions.createUser({ ...tgUser, referrerId: 0 });
        navigate('/user-start-stats', { state: { telegramUser: tgUser } });
    };

    return (
        <div className="page">
            <div className="intro-container">
                <img
                    className="coin"
                    src={coinIcon}
                    alt="coin"
                    style={{
                        width: '100%',
                        height: 'auto',
                    }}
                />
                <div className="intro-text-header">
                    👋 {t('Hello')} {tgUser ? tgUser.firstName + ' ' + tgUser.lastName : t('Can\'t Find!')}!
                </div>

                <div className="intro-text-description">
                    {t('This is simple text to introduce our app')}
                </div>

                <button className="join-button" onClick={handleJoin}>{t('Join to CHOO!')}</button>

                <a className="intro-text-link" href="https://choo.app/privacy-policy">{t('Privacy policy & Terms')}</a>
            </div>
        </div>
    );
}

export default Welcome;